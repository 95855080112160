<template>
  <StandardLayout>
    <template #heading>
      <h1>Unsere Erlebnisse</h1>
      <p>Im Winter (1.11.-28.2.) herrscht ein Fahrverbot auf dem See, so dass wir in dieser Zeit ausschließlich Saunen ohne Fahrt anbieten.
      </p>
    </template>
    <template #main>
      <div v-for="productGroup in productGroups" :key="productGroup.title">
        <h3 class="h3 text-center">
          {{ productGroup.title }}
        </h3>
        <div v-html="productGroup.description"
             class="px-1 px-md-5 mb-3 text-center text-secondary small"/>
        <div class="row row-cols-1 row-cols-md-2 mx-2 mb-3 py-3 bg-white border border-secondary"
             v-for="article in productGroup.articles"
             :key="article.id">
          <!-- product image -->
          <div class="col text-center">
            <cld-image :public-id="article.product.imageId"
                       class="img-fluid"
                       height="558"
                       fetch="auto"
                       crop="scale">
              <cld-transformation quality="auto:low"/>
            </cld-image>
          </div>
          <div class="col">
            <div class="col text-center">
              <h4 class="h4 mt-2">{{ article.product.name }}</h4>
              <div>
                {{ article.product.description }}
              </div>
              <div class="m-2">
                <router-link :to="{name: 'productDetail', params: {productId: article.product.id}, hash: '#inklusiv'}"
                             class="btn btn-outline-dark mt-1"
                             v-if="article.product.detailId">
                  Leistungen
                </router-link>
                <router-link :to="{name: 'productDetail', params: {productId: article.product.id}, hash: '#ablauf'}"
                             class="btn btn-outline-dark ml-1 mt-1"
                             v-if="article.product.detailId">
                  Ablauf
                </router-link>
              </div>
              <div class="d-none mt-0 text-warning" v-if="article.timeConstrained">
                verfügbar {{ article.validFromDate|date }}-{{ article.validUntilDate|date }}
              </div>
            </div>
            <div class="col font-weight-bolder text-center h4 mt-0">
              {{ article.grossPrice | price }}
              <span class="small">für {{ article.product.minAttendees }}<span
                  v-if="article.product.attendees > article.product.minAttendees">-{{
                  article.product.attendees
                }}</span>
                Personen</span>
              <div class="text-muted small" v-if="article.product.attendees > article.product.minAttendees">
                {{ article.attendeeProductPrice|price }} für die
                {{ article.product.attendees + 1 }}.-{{ article.product.maxAttendees }}. Person
              </div>
            </div>
            <div class="col text-center">
              <button class="btn btn-secondary m-1"
                      @click="$router.push({name:'purchaseVoucher', query: {product: article.product.id}})">
                Gutschein
              </button>
              <button class="btn btn-primary m-1"
                      @click="$router.push({name:'editReservation', query: {article: article.id}})">
                Buchen
              </button>
            </div>
          </div>
        </div>
        <div v-if="displayedDetail">
          <modal :title="displayedDetail.name"
                 @close="displayedDetail = null">
            <template v-slot:body>
              <div v-html="displayedDetail.content"/>
            </template>
            <template v-slot:footer>
              <button type="button" class="btn btn-secondary" @click="displayedDetail = null">OK</button>
            </template>
          </modal>
        </div>
      </div>
    </template>
  </StandardLayout>
</template>

<script>
import StandardLayout from "@/layouts/StandardLayout";
import {DateTime} from "luxon";
import Modal from "@/components/generic/Modal";

const LOOK_AHEAD_DAYS = 14;
const SUMMER_START = 3;
const WINTER_START = 11;

export default {
  components: {Modal, StandardLayout},
  data() {
    return {
      articles: [],
      displayedDetail: null
    }
  },
  computed: {
    tripArticles() {
      return {
        title: 'Erlebnisse mit Fahrt auf dem See',
        description: 'Deine Fahrt startet mit einer angeheizten Sauna.<br/>' +
            'Nach einer Einweisung für den Kapitän (kein Führerschein benötigt) geht es raus auf den See.<br/>' +
            'Der Sprung nach dem Saunagang von der Terrasse in den See ist unvergesslich!',
        articles: this.articles.filter(a => a.product.trip)
      }
    },
    nonTripArticles() {
      return {
        title: 'Erlebnisse ohne Fahrt',
        description: 'Bei unseren Saunen am Steg könnt Ihr Euch voll auf die Erholung fokussieren.<br/>'
            + 'Das Saunaboot liegt am äußerstem Ende des Stegs, so dass für ausreichend Privatsphäre gesorgt ist. Wir empfehlen die Abendstunden mit Blick in den Sonnenuntergang.',
        articles: this.articles.filter(a => !a.product.trip)
      }
    },
    productGroups() {
      return this.inSummer(DateTime.now().plus({days: LOOK_AHEAD_DAYS}).toFormat('yyyy-MM-dd'))
          ? [this.tripArticles, this.nonTripArticles]
          : [this.nonTripArticles, this.tripArticles]
    }
  },
  methods: {
    inSummer(dateStr) {
      const date = DateTime.fromFormat(dateStr, 'yyyy-MM-dd')
      return date.month >= SUMMER_START && date.month < WINTER_START
    },
    async loadArticles() {
      console.log('loadArticles')
      const articlesWithDuplicatedProducts = await this.$api.get('/articles', {
        params: {
          channel: process.env.VUE_APP_SPABOOT_CHANNEL_ID,
          category: 'RES'
        }
      }).then(response => response.data)
          .catch(err => this.handleError(err))
      // Artikel mit gleichem Produkt -> nur einen nehmen
      const articles = []
      const presentProductIds = new Set();
      for (let a of articlesWithDuplicatedProducts) {
        if (!presentProductIds.has(a.product.id)) {
          articles.push(a)
          presentProductIds.add(a.product.id)
        }
      }
      // zusätzlich die Personenartikel laden, um Preise für
      // zusätzliche Personen anzuzeigen
      this.$api.get('/articles', {
        params: {
          channel: process.env.VUE_APP_SPABOOT_CHANNEL_ID,
          category: 'PER'
        }
      }).then(res => {
        const personArticles = res.data
        personArticles.forEach(pa => {
          pa.product.attendeeProductFor.forEach(p => {
            for (let article of articles) {
              if (article.product.id == p.id)
                article.attendeeProductPrice = pa.grossPrice
            }
          })
        })
        this.articles = articles
      })
    },
    displayDetail({id, title}) {
      this.$api.get(`/product-details/${id}`)
          .then(res => this.displayedDetail = {
            ...res.data,
            title: title
          }).catch(err => this.handleError(err))
    }
  },
  created() {
    this.loadArticles()
  }
}
</script>
